import { SOCIALLINKS, WEDDINGSSUBORGLINKS } from '@/constants/socialLinks';
import { CDNLOGOLINK } from '@/constants/imageLinks';
import { SF_URL } from '@/constants/env';
import { ABOUT_US } from '@/constants/staticPaths';

export const CONTACTPOINT = {
  contactType: 'Customer Service',
  telephone: '+1(888) 320-2687',
};

export const LOGO = {
  '@id': `${SF_URL}/#logo`,
  '@url': CDNLOGOLINK,
};

export const ORGANIZATION = {
  name: 'The Bouqs Co.',
  '@id': `${SF_URL}/about-us#organization`,
  url: `${SF_URL}${ABOUT_US}`,
  logo: {
    '@type': 'ImageObject',
    '@id': `${SF_URL}/#logo`,
    '@url': CDNLOGOLINK,
  },
  sameAs: [
    SOCIALLINKS.twitter,
    SOCIALLINKS.facebook,
    SOCIALLINKS.pinterest,
    SOCIALLINKS.instagram,
  ],
};

export const WEDDINGSSUBORGANIZATION = {
  name: 'The Bouqs Co. Weddings & Events',
  sameAs: [
    WEDDINGSSUBORGLINKS.facebook,
    WEDDINGSSUBORGLINKS.instagram,
    WEDDINGSSUBORGLINKS.pinterest,
  ],
  url: 'https://weddings.bouqs.com/',
  '@id': 'https://weddings.bouqs.com/#organization',
};

export const WEBPAGE = {
  '@type': 'WebPage',
  '@id': `${SF_URL}/#webpage`,
  isPartOf: {
    '@id': `${SF_URL}/#website`,
  },
  url: `${SF_URL}/`,
  name: `${SF_URL}/`,
  description: 'a bouqs webpage',
};

export const WEBSITE = {
  '@id': `${SF_URL}/#website`,
  url: `${SF_URL}`,
  name: 'The Bouqs Co.',
  publisher: {
    '@id': `${SF_URL}${ABOUT_US}#organization`,
  },
};
