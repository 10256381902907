import { LogoSchemaProps, LogoSchema } from './LogoSchema.d';
import { LOGO } from '@/constants/jsonldScripts';
import JsonLdScript from '@/components/Seo/JsonLdScript';

/**
 * Builds the schema for Logo.
 * The schema can be used as part of an array of schemas
 * that are part of the '@graph' array
 *
 * Returns a object with default properties for the LogoSchema.
 * @param schema object with props needed to build schema object with custom values.
 *
 */
export const logoSchema = (
  //schema IS optinal, but if passed it can ONLY be undefined or a specific schema obj
  schema: LogoSchema | undefined = {}
): Record<string, string> => {
  const schemaTemplate = {
    '@type': 'ImageObject',
    '@id': LOGO['@id'],
    '@url': LOGO['@url'],
    ...schema,
  };
  return schemaTemplate;
};

/**
 * Creates JSX wrapper component for Logo ld+json script tag.
 *
 * Returns a script tag with the Logo schema as the sanitized inner html.
 *
 * @param schemaProps object with the calling functions schema props
 * @param pageName identifier of page(component) calling this function
 */
export const LogoSchemaScript = ({
  pageName = 'bouqs.com',
  schemaProps,
}: LogoSchemaProps): JSX.Element => {
  return (
    <JsonLdScript
      id="logo"
      pageName={pageName}
      scriptContent={logoSchema(schemaProps)}
    />
  );
};
