import { WebsiteSchemaProps, WebsiteSchema } from './WebsiteSchema.d';
import { WEBSITE } from '@/constants/jsonldScripts';
import JsonLdScript from '@/components/Seo/JsonLdScript';

/**
 * Builds the schema for Website.
 * The schema can be used as part of an array of schemas
 * that are part of the '@graph' array
 *
 * Returns a object with default properties for the WebsiteSchema.
 *
 * @param schema object with props needed to build schema object with custom values.
 */
export const websiteSchema = (
  //schema IS optinal, but if passed it can ONLY be undefined or a specific schema obj
  schema: WebsiteSchema | undefined = {}
): Record<string, unknown> => {
  // if schema is undefined, spread {}
  const schemaTemplate = {
    '@type': 'WebSite',
    name: WEBSITE.name,
    url: WEBSITE.url,
    '@id': WEBSITE['@id'],
    publisher: WEBSITE.publisher,
    ...schema,
  };
  return schemaTemplate;
};

/**
 * Creates JSX wrapper component for Website ld+json script tag.
 *
 * Returns a script tag with the Website schema as the sanitized inner html.
 *
 * @param schemaProps object with the calling functions schema props
 * @param pageName identifier of page(component) calling this function
 */
export const WebsiteSchemaScript = ({
  schemaProps,
  pageName = 'bouqs.com',
}: WebsiteSchemaProps): JSX.Element => {
  const schema = websiteSchema(schemaProps);
  return (
    <JsonLdScript pageName={pageName} id="website" scriptContent={schema} />
  );
};
