import {
  organizationSchema,
  webpageSchema,
  websiteSchema,
} from '@/components/Seo';
import JsonLdScript from '@/components/Seo/JsonLdScript';

const homePageJsonLdObj = (name, metaDescription) => {
  return {
    '@context': 'http://schema.org',
    '@graph': [
      organizationSchema(),
      webpageSchema({ name, description: metaDescription }),
      websiteSchema(),
    ],
  };
};

type homePageJsonLdScriptProps = {
  name: string;
  description: string;
};

const HomePageJsonLdScript = ({
  name,
  description,
}: homePageJsonLdScriptProps): JSX.Element => {
  return (
    <JsonLdScript
      id="homepage-jsonld-script"
      scriptContent={homePageJsonLdObj(name, description)}
    />
  );
};

export default HomePageJsonLdScript;
