import {
  ContactPointSchemaProps,
  ContactPointSchema,
} from './ContactPointSchema.d';
import { CONTACTPOINT } from '@/constants/jsonldScripts';
import JsonLdScript from '@/components/Seo/JsonLdScript';

/**
 * Builds the schema for Contactpoint.
 * The schema can be used as part of an array of schemas
 * that are part of the '@graph' array
 *
 * Returns a object with default properties for the ContactPointSchema.
 * @param schema object with props needed to build schema object with custom values.
 *
 */
export const contactPointSchema = (
  //schema IS optinal, but if passed it can ONLY be undefined or a specific schema obj
  schema: ContactPointSchema | undefined = {}
): Record<string, string> => {
  const schemaTemplate = {
    '@type': 'ContactPoint',
    contactType: CONTACTPOINT.contactType,
    telephone: CONTACTPOINT.telephone,
    ...schema,
  };
  return schemaTemplate;
};

/**
 * Creates JSX wrapper component for ContactPoint ld+json script tag.
 *
 * Returns a script tag with the ContactPoint schema as the sanitized inner html.
 *
 * @param schemaProps object with the calling functions schema props
 * @param pageName identifier of page(component) calling this function
 */
export const ContactPointSchemaScript = ({
  schemaProps,
  pageName = 'bouqs.com',
}: ContactPointSchemaProps): JSX.Element => {
  return (
    <JsonLdScript
      id="contact-point"
      scriptContent={contactPointSchema(schemaProps)}
      pageName={pageName}
    />
  );
};
