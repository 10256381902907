import { WebpageSchemaProps, WebpageSchema } from './WebpageSchema.d';
import { WEBPAGE } from '@/constants/jsonldScripts';
import JsonLdScript from '@/components/Seo/JsonLdScript';

/**
 * Builds the schema for Webpage.
 * The schema can be used as part of an array of schemas
 * that are part of the '@graph' array
 *
 * Returns a object with default properties for the WebpageSchema.
 *
 * @param schema object with props needed to build schema object with custom values.
 * key value pairs NAME and DESCRIPTION are NOT optional
 */
export const webpageSchema = (
  schema: WebpageSchema
): Record<string, unknown> => {
  const schemaTemplate = {
    '@type': 'WebPage',
    url: WEBPAGE.url,
    '@id': WEBPAGE['@id'],
    isPartOf: WEBPAGE.isPartOf,
    ...schema,
  };
  return schemaTemplate;
};

/**
 * Creates JSX wrapper component for Webpage ld+json script tag.
 *
 * Returns a script tag with the Webpage schema as the sanitized inner html.
 *
 * @param schemaProps object with the calling functions schema props
 * @param pageName identifier of page(component) calling this function
 */
export const WebpageSchemaScript = ({
  schemaProps,
  pageName = 'bouqs.com',
}: WebpageSchemaProps): JSX.Element => {
  const webPageSchemaProps = {
    name: schemaProps?.name ?? WEBPAGE.name,
    description: schemaProps?.description ?? WEBPAGE.description,
  };
  const schema = webpageSchema(webPageSchemaProps);
  return (
    <JsonLdScript id={'webpage'} scriptContent={schema} pageName={pageName} />
  );
};
