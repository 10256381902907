import { browserCookies } from '@/lib/bouqsCookies';
import typeEnforcer from '@/lib/typeEnforcer';
import isBrowser from '@/utils/isBrowser';
import { FEATURES_BASE_NAME } from '@/constants/features';

export default function useFeature(featureName: string): boolean {
  const featureRegex = new RegExp(`^${FEATURES_BASE_NAME}`);

  try {
    if (!featureName.match(featureRegex)) {
      throw new Error(
        `Feature Toggle: ${featureName} names must be prefixed with ${FEATURES_BASE_NAME}`
      );
    }
    if (!isBrowser()) {
      return null;
    }
  } catch (e) {
    console.error(`useFeature error: ${e}`);
    return null;
  }

  const isToggled = typeEnforcer.boolean(browserCookies.get(featureName));

  return isToggled;
}
