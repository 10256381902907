import {
  OrganizationSchemaProps,
  OrganizationSchema,
} from './OrganizationSchema.d';
import {
  ORGANIZATION,
  WEDDINGSSUBORGANIZATION,
} from '@/constants/jsonldScripts';
import { contactPointSchema } from '@/components/Seo/ContactPointSchema';
import { logoSchema } from '@/components/Seo/LogoSchema';
import JsonLdScript from '@/components/Seo/JsonLdScript';

/**
 * Builds the schema for the weddings suborganization.
 * The schema can be used as part of an array of schemas
 * that are part of the '@graph' array
 *
 * Returns a object with default properties for the weddingsSuborganizationSchema.
 *
 */
export const weddingsSubOrganizationSchema = (): Record<
  string,
  string | string[]
> => {
  const subOrganizationSchemaTemplate = {
    '@type': 'Organization',
    name: WEDDINGSSUBORGANIZATION.name,
    '@id': WEDDINGSSUBORGANIZATION['@id'],
    url: WEDDINGSSUBORGANIZATION.url,
    sameAs: WEDDINGSSUBORGANIZATION.sameAs,
  };
  return subOrganizationSchemaTemplate;
};

/**
 * Builds the schema for organization.
 * The schema can be used as part of an array of schemas
 * that are part of the '@graph' array
 *
 * Returns a object with default properties for the OrganizationSchema.
 *
 * @param schema object with props needed to build schema object with custom values.
 * If custom data isn't available {} MUST be passed
 */
export const organizationSchema = (
  //schema IS optinal, but if passed it can ONLY be undefined or a specific schema obj
  schema: OrganizationSchema | undefined = {}
): Record<string, unknown> => {
  const subOrganization = schema?.suborganizations
    ? [weddingsSubOrganizationSchema(), ...schema.suborganizations]
    : [weddingsSubOrganizationSchema()];

  /**
   * Due to the nature of organization being a larger schema with some smaller schemas
   * the passed in schema parameter is spread in the middle so as to ordverride, if present,
   * name, url, sameAs and @id, but NOT logo, contactPoint, and subOrganization since their
   * schemas are built by functions that use either no object or a custom schema object
   * to build an object with custom or default key value pairs.
   */
  const schemaTemplate = {
    '@type': 'Organization',
    name: ORGANIZATION.name,
    url: ORGANIZATION.url,
    sameAs: ORGANIZATION.sameAs,
    '@id': ORGANIZATION['@id'],
    ...schema,
    logo: logoSchema(schema?.logo ?? {}),
    contactPoint: contactPointSchema(schema?.contactPoint ?? {}),
    subOrganization,
  };
  return schemaTemplate;
};

/**
 * Creates JSX wrapper component for Organization ld+json script tag.
 *
 * Returns a script tag with the Organization schema as the sanitized inner html.
 *
 * @param schemaProps object with the calling functions schema props
 * @param pageName identifier of page(component) calling this function
 */
export const OrganizationSchemaScript = ({
  schemaProps,
  pageName = 'bouqs.com',
}: OrganizationSchemaProps): JSX.Element => {
  const schema = organizationSchema(schemaProps);
  return (
    <JsonLdScript
      pageName={pageName}
      id="organization"
      scriptContent={schema}
    />
  );
};
